import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import { graphql } from 'gatsby';
import SeasonYear from '../../components/SeasonYear';
import SLACImage from '../../assets/images/publication-banners/salt-lake-acting-company.jpg';

export const query = graphql`
  query SlacQuery {
    allSlacCsv {
      nodes {
        CD
        FR
        Quantity
        RUNNING_DATES
        SHOW
        Image
      }
    }
  }
`;

export default function SaltLakeActingCompany({ data }) {
  const slacNodes = data.allSlacCsv.nodes;
  // console.log(balletNodes);

  const imageRef = '/season-images/slac/';
  // append the image reference with the path to the image via the static folder

  return (
    <Layout>
      <Helmet>
        <title>Salt Lake Acting Company - Mills Publishing Inc.</title>
        <meta
          name="description"
          content="Advertising and event information for Salt Lake Acting Company’s season playbills. Please contact Mills Publishing for availability and rates."
        />
      </Helmet>

      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <header className="major">
              <h1>Salt Lake Acting Company</h1>
            </header>
            <span className="image main">
              <img src={SLACImage} alt="" />
            </span>
            <p className="box">
              Support a local theatre and, in return, receive exposure to a
              developing community. Salt Lake Acting Company boasts five to
              seven regional/world premieres every season, and is the perfect
              place to advertise if you want to be a part of liberal and
              highly-educated audience.
            </p>
            <ul className="actions">
              <li>
                <a
                  href="https://www.saltlakeactingcompany.org/"
                  className="button special"
                >
                  <i className="icon fa-globe"> </i>Salt Lake Acting Company
                  Website
                </a>
              </li>
            </ul>
            <h2>
              Salt Lake Acting Company <SeasonYear /> Season Information
            </h2>
            <p>
              <em>
                Dates are subject to change. Please contact us if you have any
                questions.
              </em>
            </p>
            <div className="grid-wrapper">
              {/* Calls the season json, accesses the ecclesSeason array, and maps/creates a new array */}
              {slacNodes.map((node) => (
                <div className="col-4 box" key={node.id}>
                  <img
                    style={{ width: 100 + '%' }}
                    src={imageRef + node.Image}
                  />
                  <h3>{node.SHOW}</h3>
                  <strong>{node.RUNNING_DATES}</strong> <br />
                  <br />
                  <h5>Advertising Information</h5>
                  <section className="box">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            File Ready Deadline: <br />
                            {node.FR}
                          </td>
                        </tr>
                        {/* <tr>
                          <td>Advertising Exposure: {node.exposure}</td>
                        </tr> */}
                        <tr>
                          <td>Print Quantity: {node.Quantity}</td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
